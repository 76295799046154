<template>
  <BaseLayout v-slot>
    <Breadcrumbs />
    <section class="page-not-found pt-8 pb-20">
      <b-container>
        <b-row>
          <b-col lg="3">
            <SidebarLinks />
          </b-col>
          <b-col lg="9" class="not-found-img pb-30 pb-lg-0">
            <span class="h1 d-block text-center mb-30 mb-lg-45 px-30 px-md-0">{{
              $t("404_line_1")
            }}</span>
            <div class="img mb-30 mb-lg-60">
              <img src="@/esf_lommel_dt1_europe/assets/not-found.png" alt="Not Found" />
            </div>
            <span class="h3 d-block text-center font-weight-normal"
              >{{ $t("404_line_2") }}
            </span>
            <span class="h3 d-block text-center font-weight-normal">{{
              $t("404_line_3")
            }}</span>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import SidebarLinks from "@/esf_lommel_dt1_europe/core/components//SidebarLinks";

export default {
  name: "PageNotFound",
  components: {
    BaseLayout,
    Breadcrumbs,
    SidebarLinks,
  },
  computed: {
    sidebarData() {
      return []; //this.$store.getters["menu/getMenuItems"];
    },
  },

  data() {
    return {
      /*
      sidebarData: {
        heading: "Categorie",
        options: [
          { displayName: "Gentlemen", linkTo: "#", active: false },
          { displayName: "Women", linkTo: "#", active: false },
          { displayName: "Bicycle clothing sets", linkTo: "#", active: false },
          { displayName: "Children", linkTo: "#", active: false },
          { displayName: "Cycling shoes", linkTo: "#", active: false },
          { displayName: "For your bike", linkTo: "#", active: false },
          { displayName: "For your body", linkTo: "#", active: false },
          { displayName: "Brands", linkTo: "#", active: false },
          { displayName: "Cycling books", linkTo: "#", active: false },
          { displayName: "Gift voucher", linkTo: "#", active: false },
        ],
      },
      */
    };
  },
  mounted() {
    const bcrumb = { current: "404", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";

.img {
  width: 100%;
  max-width: 743px;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.not-found-img {
  order: -1;

  @include media("screen", ">=desktop") {
    order: 0;
  }
}
</style>
